// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,   
  
    //Dev
    AUTH_REST_URI: "https://d-login.pnp.co.za",
    BASE_URI: "https://d-api.pnp.co.za",
    CLIENT_ID: "ef9612b0-ea4d-46d2-adcf-1ac45fe1093d",
    SECRET_ID: "9NCTmfARoYBPzi8ePBz6LnyG6F5BB8ccN3x4Een4",
    RESET_PASSWORD_URI: "https://hyb-dev.pnp.co.za/pnphybris/v2/pnp/oidc/reset-password?lang=en&curr=ZAR&redirectUrl=pnpmvnoapp://login",
    S3_PROD_CDN: 'https://pnp-cdn-prod.s3.eu-west-1.amazonaws.com/mvno',
    S3_DEV_QA_CDN: 'https://pnp-cdn-test.s3.eu-west-1.amazonaws.com/mvno',
    CMS_API_CLIENT_ID: 1,
    CMS_API_URL: "https://cms.pnp.co.za",
    CMS_API_CLIENT_SECRET: "aKQePPr5iKQGragWmX1hEBCQm4kCJDoLzZSNefnP",
    RICA_URI: "https://registration-pnp-mobile-webapp.kyc.business/onboarding",
    PAYU_RETURN_URI: "https://d-pnpmobile.pnp.co.za/payu/return",
    PAYU_CANCEL_URI: "https://d-pnpmobile.pnp.co.za/payu/cancel"
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  